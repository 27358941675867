import Vue from 'vue'
import App from './App.vue'
import {Message, MessageBox} from 'element-ui'
import Element from 'element-ui'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import "element-ui/lib/theme-chalk/index.css"
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import "@/styles/body.css"


// highlight.js代码高亮插件
import 'font-awesome/css/font-awesome.min.css';

// 使用vuex
import store from '@/store'
// 使用vue-router
import router from '@/router'
// import { Loading } from 'element-ui';

// 使用echarts ，展示考研所有数据信息
import * as echarts from 'echarts'
import Global from "@/config/global";

Vue.prototype.Global = Global;
Vue.config.productionTip = false;
// 挂载到$message上
Vue.prototype.$message = Message;
Vue.prototype.$mconfirm = MessageBox.confirm;

Vue.component(CollapseTransition.name, CollapseTransition);
Vue.prototype.$echarts = echarts;


/**
 * 添加富文本编辑器
 */

// 使用element-ui
Vue.use(Element, {
    //size: Cookies.get('size') || 'medium', // set element-ui default size
    locale: enLang // 如果使用中文，无需设置，请删除
})
import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';


// highlightjs
import hljs from 'highlight.js';

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';

import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';

VMdEditor.use(createKatexPlugin());
VMdEditor.use(createEmojiPlugin());
VMdEditor.use(createTipPlugin());
VMdEditor.use(createLineNumbertPlugin())
VMdEditor.Codemirror = Codemirror;

VMdEditor.use(
    githubTheme,
    {
        Hljs: hljs,
        extend(md) {
            // let mk = require('markdown-it-katex');
            //  md.use(mk);
            //  md.use(require("markdown-it-latex"))
        }
    },
);
Vue.use(VMdEditor);

// 预览组件
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';

VMdPreview.use(
    vuepressTheme,
    {
        Prism,
        // Hljs: hljs,
        extend(md) {
            // let mk = require('markdown-it-katex');
            // md.use(require("markdown-toc"))
             // md.use(require("markdown-it-latex"))
        }
    }
);

VMdPreview.use(createKatexPlugin());
VMdPreview.use(createEmojiPlugin());
VMdPreview.use(createTipPlugin());
VMdPreview.use(createLineNumbertPlugin())
Vue.use(VMdPreview);



new Vue({
    // 路由
    router,
    // 存储
    store,
    render: h => h(App),

}).$mount('#app')
