import Vue from 'vue'
import Router from 'vue-router'
import {getToken} from "@/utils/auth";

Vue.use(Router)

// 首页路由
export const constantRoutes = {
    path: '/',
    component: () => import("@/views/index/index"),
    alwaysShow: true,
    // name: 'index',
    children: [
        {
            path: '',
            component: () => import("@/views/index/first"),
        },
        study,
        blog,
        video,
        clock,
        paper,
        chat,
        self,
        add,
        editor,
        account,
        crowd,
        preview
    ]
}


import self from "@/router/modules/self";
import chat from "@/router/modules/chat";
import blog from "@/router/modules/blog";
import live from "@/router/modules/live";
import video from "@/router/modules/video";
import login from "@/router/modules/login";
import paper from "@/router/modules/paper"
import clock from "@/router/modules/clock";
import user from "@/store/modules/user";
import help from "@/router/modules/help";
import add from "@/router/modules/add"
import editor from "@/router/modules/editor"
import account from "@/router/modules/account";
import crowd from "@/router/modules/crowd";
import preview from "@/router/modules/preview";

export const asyncRoutes = [
    /** 可以分开模块来写 **/
    constantRoutes,
    login,
    help
]

const createRouter = () => new Router({
    scrollBehavior: () => ({y: 0}),
    routes: asyncRoutes,
    mode: 'history',  //去掉url中的#
})

// 创建路由
const router = createRouter()

// 重新配置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

import store from '@/store'
import study from "@/router/modules/study";
// 路由守卫
router.beforeEach((to, from, next) => {
    // to要跳转到的路径
    // from从哪个路径来
    // next往下执行的回调
    // 在localStorage中获取token
    let token = getToken();
    // 根据页面设置的meta判断该页面是否需要登录
    if (to.meta.requireAuth) {
        // 如果token存在直接跳转
        if (token) {
            // 有token信息,但是其他信息已经缺失
            if (store.state.user.isLogin === false) {
                store.dispatch("user/getInfo").then(result => {
                    // console.log(result)
                }).catch(error => {
                    this.$message.error(error.message)
                })
            }
            next()
        } else {
            // 否则跳转到login登录页面
            next({
                path: '/login',
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        // 如果不需要登录，则直接跳转到对应页面
        next()
    }
})
export default router
