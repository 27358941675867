export  default
{
    // 私信
    path: '/chat',
    component: () => import("@/views/chat/index"),
    alwaysShow: true,
    meta: {
        requireAuth:true,
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [

    ]
}