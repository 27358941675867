import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

import user from "@/store/modules/user";
import app from "@/store/modules/app";
import permission from "@/store/modules/permission";
import chat from "@/store/modules/chat"

const modules = {
    user ,
    app ,
    permission,
    chat
}
/**
 *
 * @type {Store<unknown>}
 */
const store = new Vuex.Store({
    modules,
    getters,
    // 解决刷新vuex状态丢失问题
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(val) {
            return {
                // 只储存state中的assessmentData
                dict: val.dict
            }
        }
    })]
})
// 导出
export default store
