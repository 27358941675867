
// 用户的 全局变量
const state = {
    friend:"",
    group:"",
    data:"",
}

const mutations = {

    SET_FRIEND: (state, friend) => {
        state.friend = friend
    },
    SET_GROUP: (state, group) => {
        state.group = group;
    },
    SET_DATA: (state, data) => {
        state.data = data
    },
    SET_DATA_BY_ID: (state, data) => {
        let map=state.data.get(data.id);
        map.push(data.content)
        state.data.set(data.id,map)
    },
}


const actions = {
    /**
     * 设置全局的聊天信息
     * @param commit
     * @param allData
     */
    setAllData({commit}, data){
        commit("SET_DATA",data)
    },

    /**
     * 设置当前用户的信息
     * @param commit
     * @param id
     */

    /**
     * 修改当前用户信息
     * @param commit
     * @param friend
     */
    changeFriend({commit},friend){
        commit("SET_FRIEND",friend)
    },
    changeData({commit},data){
        commit("SET_DATA_BY_ID",data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
