export default   // 展示博客的地方
{
    path: 'study',
    component: () => import("@/views/study"),
    // redirect: '/permission/page',
    alwaysShow: true, // will always show the root menu
    // 设置权限地方
    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        {
            path: '',
            component: () => import('@/views/study/School'),
            name: 'school',
            meta: {
                title: 'Role Permission',
                roles: ['admin', 'teacher', "user"]
            }
        },
        {
            path: 'category',
            component: () => import('@/views/study/Category'),
            name: 'category',
            meta: {
                title: 'Role Permission',
                roles: ['admin', 'teacher', "user"]
            }
        },
        {
            path: 'graduate',
            component: () => import('@/views/study/Graduate'),
            name: 'graduate',
            meta: {
                title: 'Role Permission',
                roles: ['admin', 'teacher', "user"]
            }
        }
    ]
}
