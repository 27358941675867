import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const IdKey="kaoYan-Id"
const NameKey="kaoYan_Name"
const AvatarKey="kaoYan_Avatar"
const Introduction="kaoYan_introduction"

export function getIntroduction() {
  return Cookies.get(Introduction)
}
export function setIntroduction(id) {
  return Cookies.set(Introduction,id)
}


export function getId() {
  return Cookies.get(IdKey)
}
export function setId(id) {
  return Cookies.set(IdKey,id)
}
export function removeId() {
  return Cookies.remove(IdKey)
}
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getName() {
  console.log("拿到的用户名字为",Cookies.get(NameKey))
  return Cookies.get(NameKey)
}

export function setName(name) {
  return Cookies.set(NameKey, name)
}

export function removeName() {
  return Cookies.remove(NameKey)
}

export function getAvatar() {
  return Cookies.get(AvatarKey)
}

export function setAvatar(name) {
  return Cookies.set(AvatarKey, name)
}

export function removeAvatar() {
  return Cookies.remove(AvatarKey)
}

