export default
{
    //展示直播的地方
    path: '/paper',
    component: () => import("@/views/paper/index"),
    alwaysShow: true,
    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        // 关于试卷
        {
            path: '',
            component: () => import("@/views/paper/index/index"),
        },
        // 关于试卷
        {
            path: 'modify',
            component: () => import("@/views/paper/self/modify-paper"),
        },
        // 个人试卷库
        {
            path: 'self',
            component: () => import("@/views/paper/self"),
            meta: {
                requireAuth:true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 个人试卷库
        {
            path: 'paper',
            component: () => import("@/views/paper/paper"),
            meta: {
                requireAuth:true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 错题集
        {
            path: 'error',
            component: () => import("@/views/paper/self/error"),
            meta: {
                requireAuth:true,
                title: '账号管理',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 添加试卷
        {
            path: 'addTest',
            component: () => import("@/views/paper/self/add-test"),
            meta: {
                requireAuth:true,
                title: '账号管理',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 添加试卷
        {
            path: 'addPaper',
            component: () => import("@/views/paper/self/add-paper"),
            meta: {
                requireAuth:true,
                title: '账号管理',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 展示试卷
        {
            path: 'showTest',
            component: () => import("@/views/paper/index/show-test"),
        },
        // 提问区
        {
            path: 'answer',
            component: () => import("@/views/paper/answer"),
        },
    ]
}
