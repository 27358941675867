export default {
    //展示直播的地方
    path: 'preview',
    component: () => import("@/views/preview/index"),
    alwaysShow: true,

    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        // 关于试卷
        {
            path: 'pdf',
            component: () => import("@/views/preview/my-pdf"),
            name: "pdf",
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            path: 'ppt',
            component: () => import("@/views/preview/my-ppt"),
            name: "ppt",
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
    ]
}
