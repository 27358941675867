export default {
    path: '/self',
    component: () => import("@/views/self/index"),
    alwaysShow: true,
    // 设置权限地方
    meta: {
        requireAuth: true,
        title: '个人中心',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        {
            // 写博客的地方
            path: '',
            component: () => import("@/views/self/blog-item"),
            alwaysShow: true,
            // 设置权限地方
            meta: {
                requireAuth: true,
                title: '',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"]
            },
        },
        {
            // 写博客的地方
            path: 'course',
            component: () => import("@/views/self/course-wrap"),
            alwaysShow: true,
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"]
            },
        },
    ]
}
