export default
{
    //展示直播的地方
    path: '/clock',
    component: () => import("@/views/clock/index"),
    alwaysShow: true,
    name:"clock",
    meta: {
        requireAuth:true,
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
    ]
}