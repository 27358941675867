const getters = {
  sidebar: state => state.app.sidebar,
  // 页面的大小
  size: state => state.app.size,
  device: state => state.app.device,
  // 用户的token 信息
  token: state => state.user.token,
  id: state=>state.user.id,
  // 用户的头像
  avatar: state => state.user.avatar,
  // 用户的名字
  name: state => state.user.name,
  isLogin: state=>state.user.isLogin,
  // 个人介绍
  introduction: state => state.user.introduction,
  friend:state => state.chat.friend,
  group:state => state.chat.groupId,
  data:state=>state.chat.data,
}
export default getters
