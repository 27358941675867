// const BASE_URL = 'https://aliyun.jokerak.com:8082'
// const BASE_URL = 'https://aliyun.jokerak.com'
const BASE_URL = 'https://fun.jokerak.com'
// const BASE_URL = 'http://localhost:9000'

const uploadUrl = BASE_URL + "/upload"
const uploadInfoUrl = BASE_URL + "/uploadInfo/upload"
const vodUrl=BASE_URL+"/video/upload"
// 富文本编辑器配置
const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{script: "sub"}, {script: "super"}], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{indent: "-1"}, {indent: "+1"}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{direction: "rtl"}], // 文本方向-----[{'direction': 'rtl'}]
    [{size: ["small", false, "large", "huge"]}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{color: []}, {background: []}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{font: []}], // 字体种类-----[{ font: [] }]
    [{align: []}], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
]
const toolbars = {
    bold: true, // 粗体
    italic: true, // 斜体
    header: true, // 标题
    underline: true, // 下划线
    strikethrough: true, // 中划线
    mark: true, // 标记
    superscript: true, // 上角标
    subscript: true, // 下角标
    quote: true, // 引用
    ol: true, // 有序列表
    ul: true, // 无序列表
    link: true, // 链接
    imagelink: true, // 图片链接
    code: true, // code
    table: true, // 表格
    fullscreen: true, // 全屏编辑
    readmodel: true, // 沉浸式阅读
    htmlcode: true, // 展示html源码
    help: true, // 帮助
    /* 1.3.5 */
    undo: true, // 上一步
    redo: true, // 下一步
    trash: true, // 清空
    save: true, // 保存（触发events中的save事件）
    /* 1.4.2 */
    navigation: true, // 导航目录
    /* 2.1.8 */
    alignleft: true, // 左对齐
    aligncenter: true, // 居中
    alignright: true, // 右对齐
    /* 2.2.1 */
    subfield: true, // 单双栏模式
    preview: true, // 预览
};
export const options = [
    {value: 0, label: 'Python'},
    {value: 1, label: 'Java'},
    {value: 2, label: '计算机面试'},
    {value: 3, label: '人工智能'},
    {value: 4, label: '考研'},
    {value: 5, label: '移动开发'},
    {value: 6, label: '前端开发'},
    {value: 7, label: '物联网'},
    {value: 8, label: '游戏开发'},
    {value: 9, label: '音视频开发'},
    {value: 10, label: '运维'},
    {value: 11, label: '区块链'},
    {value: 12, label: '架构'}
];
export default {
    uploadUrl,
    BASE_URL,
    toolbarOptions,
    options,
    uploadInfoUrl,
    toolbars,
    vodUrl
}
