export default   // 展示博客的地方
{
    path: '/blog',
    component: () => import("@/views/blog/index"),
    alwaysShow: true,
    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        {
            // 展示博客的地方
            path: '',
            component: () => import("@/views/blog/Blog"),
            name: "blog/index",
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            // 展示博客的地方
            path: 'show',
            component: () => import("@/views/blog/Show"),
            name: "show",
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"]
            },
            children: []
        },
        {
            // 展示博客的地方
            path: 'show2',
            component: () => import("@/views/blog/Show2"),
            name: "show2",
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"]
            },
            children: []
        },

    ]
}
