import {login, logout, getInfo, register, telephoneCode} from "@/api/user"
import {
    getToken,
    setToken,
    removeToken,
    getId,
    setId,
    getName,
    setName,
    getAvatar,
    setIntroduction,
    getIntroduction,
    setAvatar,
    removeId, removeName, removeAvatar
} from '@/utils/auth'

import router, {resetRouter} from '@/router'
import {isEmpty} from "@/utils/StringUtils";
import fa from "element-ui/src/locale/lang/fa";
// 用户的 全局变量
const state = {
    // 用户是否登录
    isLogin: false,
    // 用户的id
    id: getId(),
    // 用户的token
    token: getToken(),
    // 用户的姓名
    name: getName(),
    // 用户的头像网址信息
    avatar: getAvatar(),
    // 用户的介绍
    introduction: getIntroduction(),
    // 所选的省份，默认是北京
    province: "",
    // 所选的专业代码
    categoryCode: ""
}

const mutations = {
    SET_IS_LOGIN: (state, isLogin) => {
        state.isLogin = isLogin
    },
    SET_ID: (state, id) => {
        setId(id)
        state.id = id
    },
    SET_TOKEN: (state, token) => {
        setToken(token)
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        setIntroduction(introduction)
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        setName(name)
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        setAvatar(avatar)
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    }
}

const actions = {

    /**
     * 使用手机号码来进行登录
     * @param commit
     * @param userInfo
     * @returns {Promise<unknown>}
     */
    code({commit}, userInfo) {
        let telephone = userInfo.telephone;
        let code = userInfo.code;
        return new Promise((resolve, reject) => {
            telephoneCode(telephone, code).then(result => {
                commit("SET_TOKEN", result.data.token)
                resolve("手机号码登录成功")
            }).catch(error => {
                reject(error)
            })
        })
    },
    /**
     *人脸识别成功后，使用token 进行登录
     * @param commit
     * @param token
     * @returns {Promise<unknown>}
     */
    face({commit}, token) {
        commit("SET_TOKEN", token);
        // 放置token
        const mToken = token;
        return new Promise((resolve, reject) => {
            getInfo(mToken).then(result => {
                const user= result.data.user;
                commit("SET_ID", user.id);
                commit('SET_NAME', user.username)
                commit('SET_AVATAR', user.userImg)
                commit('SET_INTRODUCTION', user.userIntroduce)
                commit("SET_IS_LOGIN", true)
                resolve("获取用户信息成功")
            }).catch(error => {
                reject(error)
            })
        });
    },


    /**
     * 使用用户昵称和 密码进行登录
     * @param commit
     * @param userInfo
     * @returns {Promise<unknown>}
     */
    login({commit}, userInfo) {
        const {username, password} = userInfo
        return new Promise((resolve, reject) => {
            login({username: username.trim(), password: password}).then(result => {
                console.log("用户登录成功")
                console.log(result)
                // 设置用户的token信息
                commit("SET_TOKEN", result.data.token)
                setToken(result.data.token)
                resolve("用户名+密码登录成功")
            }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * 注册信息
     * @param commit
     * @param userInfo
     * @returns {Promise<unknown>}
     */
    register({commit}, userInfo) {
        console.log(userInfo)
        return new Promise((resolve, reject) => {
            register(userInfo).then(response => {
                const data = response.data.register;
                if (data === "成功") {
                    resolve("注册成功")
                } else {
                    reject();
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    /**
     * 根据token 获取用户信息
     * @param commit
     * @param state
     * @returns {Promise<unknown>}
     */
    getInfoFromCookie({commit, state}) {
        let token = getToken();
        if (!isEmpty(token)) {
            commit("SET_TOKEN", token)
        }
        let id = getId();
        if (!isEmpty(id)) {
            console.log("----" + id)
            commit("SET_ID", id);
            commit("SET_IS_LOGIN", true)
        }
        let username = getName();
        if (!isEmpty(username)) {
            commit('SET_NAME', username)
        }

        let avatar = getAvatar();
        if (isEmpty(avatar)) {
            commit("SET_AVATAR", avatar)
        }
        commit("SET_IS_LOGIN", true)
    },
    /**
     * 根据token 用户信息
     * @param commit
     * @param state
     * @returns {Promise<unknown>}
     */
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(response => {
                const user = response.data.user;
                commit("SET_ID", user.id);
                commit('SET_NAME', user.username)
                commit('SET_AVATAR', user.userImg)
                commit('SET_INTRODUCTION', user.userIntroduce)
                commit("SET_IS_LOGIN", true)
                resolve("获取用户信息成功")
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            removeToken();
            removeId();
            removeName();
            removeAvatar();
            commit("SET_NAME","")
            commit("SET_AVATAR","")
            commit("SET_IS_LOGIN",false)
            commit("SET_ID","")
            commit("SET_TOKEN","")
            commit("SET_INTRODUCTION","");
            resolve("退出成功");
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles({commit, dispatch}, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const {roles} = await dispatch('getInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)
        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, {root: true})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
