import axios from 'axios'
import store from '@/store'
import {getToken} from '@/utils/auth'
import Global from "@/config/global";
import {Message} from 'element-ui'

// 创建一个
const service = axios.create({
    baseURL: Global.BASE_URL,
    timeout: 20000 // request timeout
})

/**
 * 每个请求连接前，必须 添加 token
 */
service.interceptors.request.use(
    config => {
        // config.headers['Access-Control-Allow-Origin']="*"
        // 添加token的地方
        if (getToken()) {
            // console.log(getToken())
            //  获取token
            config.headers['token'] = getToken();

        }
        return config
    },
    error => {
        // 错误的信息
        console.log(error) // for debug
        return Promise.reject(error)
    }
)


/**
 * 对于请求内容返回结果不是200 的，全部报错
 */
service.interceptors.response.use(
    response => {
        //如果返回的状态 不是200,说明是错误的
        if (response.status !== 200) {
            // Message({
            //     message: response.data || 'Error',
            //     type: 'error',
            //     duration: 3 * 1000
            // })
            return Promise.reject(new Error(response.data || 'Error'))
        } else {
            // 20001 表示，执行了全局处理
            if (response.data.code === 20001) {
                // Message({
                //     message: response.data || 'Error',
                //     type: 'error',
                //     duration: 3 * 1000
                // })
                // return Promise.reject(new Error(response.data || 'Error'))
                return Promise.reject(response.data)
            }

            return response.data
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service

