export default {
    //展示课程的地方
    path: '/video',
    component: () => import("@/views/video/index"),
    alwaysShow: true,
    // 设置权限地方
    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"]
    },
    children: [
        {
            path: '',
            component: () => import("@/views/video/home-page"),
        },
        {
            //展示
            path: 'show/:id',
            name: "video-show",
            component: () => import("@/views/video/show/index"),
            alwaysShow: true,
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
            children: [
                {
                    path: '',
                    component: () => import("@/views/video/show/show"),
                },
                {
                    path: 'courseware',
                    component: () => import("@/views/video/show/courseware"),
                },
                {
                    path: 'exam',
                    component: () => import("@/views/video/show/exam"),
                },
            ]

        }
    ]
}
