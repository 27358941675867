export function isEmpty(temp) {
    if (temp === undefined || temp === "" || temp == null) {
        return true;
    }
    return false;
}

export function isPhone() {
    let sUserAgent = navigator.userAgent.toLowerCase();
    return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);
}
