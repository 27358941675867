import service from '@/utils/request'

/**
 * 对于 user 用户的登录
 *
 */
export function login(data) {
    return service({
        url: 'user/login',
        method: 'post',
        params: data
    })
}

/**
 * 根据token ,获取用户的信息
 * @param token
 * @returns {AxiosPromise} 返回信息
 */
export function getInfo(token) {
    return service({
        url: '/user/getInfoByToken',
        method: 'post',
        params: {token}
    })
}

export function getUserInfoByUserId(userId) {
    return service({
        url: '/user/getUserInfoByUserId',
        method: 'post',
        params: {userId}
    })
}

/**
 * 添加好友信息
 * @param userId 用户id
 * @param friendId 添加好友的id
 * @returns {AxiosPromise}
 */
export function addFriend(userId, friendId) {
    return service({
        url: '/user/addFriend',
        method: 'post',
        params: {userId, friendId}
    })
}

/**
 * 获取朋友信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function getFriend(userId) {
    return service({
        url: '/user/getFriend',
        method: 'post',
        params: {userId}
    })
}


export function getAllFriend(userId) {
    return service({
        url: '/user/addAllFriend',
        method: 'post',
        params: {userId}
    })
}

/**
 * 登出
 * @returns {AxiosPromise}
 */
export function logout() {
    return service({
        url: '/vue-element-admin/user/logout',
        method: 'post'
    })
}

/**
 * 注册用户信息
 * @param user 用户信息
 * @returns {*}
 */
export function register(user) {
    return service(
        {
            url: '/user/register',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: user,
        })
}

/**
 * 发送验证码
 * @param telephone 电话号码
 * @returns {AxiosPromise}
 */
export function telephone(telephone) {
    return service(
        {
            url: '/msm/send',
            method: 'post',
            params: {telephone}
        })
}

/**
 * 使用电话号码+验证码进行登录
 * @param telephone 电话号码
 * @param code 验证码
 * @returns {AxiosPromise}
 */
export function telephoneCode(telephone, code) {
    return service(
        {
            url: '/user/telephone',
            method: 'post',
            params: {telephone, code}
        })
}

/**
 * 添加人脸信息
 * @param formData 人脸信息
 * @returns {AxiosPromise}
 */
export function addFace(formData) {

    return service(
        {
            url: '/user/addFace',
            method: 'post',
            data: formData
        })
}

/**
 *
 * @param form 图片信息
 * @returns {*}
 */
export function searchFace(form) {

    return service(
        {
            url: '/user/searchFace',
            method: 'post',
            data: form
        })
}

/**
 * 根据用户id，来获取用户的基本信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function getInfoById(userId) {
    return service(
        {
            url: '/user/getInfoById',
            method: 'post',
            params: {
                userId
            }
        })
}

/**
 * 修改用户密码
 * @param user
 * @returns {*}
 */
export function updatePassword(user) {
    return service(
        {
            url: '/user/updatePassword',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: user
        })
}

/**
 * 更新电话号码
 * @param userId 用户的id
 * @param telephone
 * @param code
 * @returns {AxiosPromise}
 */
export function updateTelephone(userId, telephone, code) {
    return service(
        {
            url: '/user/updateTelephone',
            method: 'post',
            params: {
                userId, telephone, code
            }
        })
}

/**
 * 更新用户信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function updateInfo(user) {
    return service(
        {
            url: '/user/updateInfo',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: user
        })
}

/**
 * 更新用户的具体信息
 * @param user
 * @returns {*}
 */
export function updateUserInfo(user) {
    return service(
        {
            url: '/user/updateUserInfo',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: user
        })
}

/**
 * 更新用户头像
 * @returns {AxiosPromise}
 * @param id 用户id
 * @param url
 */
export function updateImage(id, url) {
    return service(
        {
            url: '/user/updateImage',
            method: 'post',
            params: {
                id, url
            }
        })
}