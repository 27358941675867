export default
{
    path: '/login',
    component: () => import('@/views/login/index'),
    name: 'login',
    meta: {
        title: 'Role Permission',
        roles: ['admin', 'teacher', "user"]
    }
}
