export default
{
    path: '/account',
    component: () => import("@/views/account/index"),
    alwaysShow: true,
    // 设置权限地方
    meta: {
        requireAuth:true,
        title: '账号管理',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        {
            path: '',
            component: () => import("@/views/account/user-info"),
            meta: {
                requireAuth:true,
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            // 写博客的地方
            path: 'collect',
            component: () => import("@/views/self/collect"),
            alwaysShow: true,
            // 设置权限地方
            meta: {
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"]
            },
        },

        {
            path: 'faceAdd',
            component: () => import("@/views/account/FaceAdd"),
            meta: {
                requireAuth:true,
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            path: 'modifyPassword',
            component: () => import("@/views/account/ModifyPassword"),
            meta: {
                requireAuth:true,
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
    ]
}
