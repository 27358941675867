export default {
    //展示直播的地方
    path: '/editor',
    component: () => import("@/views/editor/index"),
    alwaysShow: true,
    name: "editor",
    children: [
        {
            path: '',
            component: () => import("@/views/editor/home-page"),
        },
        // {
        //     path: 'my-vue-monaco-editor',
        //     component: () => import("@/views/editor/my-vue-monaco-editor"),
        // },
        // {
        //     path: 'my-vue-ace',
        //     component: () => import("@/views/editor/my-vue-ace"),
        // },
        // {
        //     path: 'my-CodeMirror',
        //     component: () => import("@/views/editor/my-CodeMirror"),
        // }
    ]
}