export default
{
    //展示直播的地方
    path: '/help',
    component: () => import("@/views/help/index"),
    alwaysShow: true,
    name:"help",
    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
}