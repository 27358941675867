export default
{
    //展示直播的地方
    path: '/add',
    component: () => import("@/views/add/index"),
    alwaysShow: true,
    children: [
        {
            path: 'editor',
            component: () => import("@/views/add/my-mavon-editor"),
            meta: {
                requireAuth:true,
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            path: 'course',
            component: () => import("@/views/add/course-add"),
            meta: {
                requireAuth:true,
                title: 'Permission',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // {
        //     path: 'My-Vue-Quill-Editor',
        //     component: () => import("@/views/add/My-Vue-Quill-Editor.vue"),
        // }
    ]
}