export default {
    //展示直播的地方
    path: 'crowd/:id',
    component: () => import("@/views/crowd/index"),
    alwaysShow: true,

    meta: {
        title: 'Permission',
        icon: 'lock',
        roles: ['admin', 'teacher', "user"] // you can set roles in root nav
    },
    children: [
        // 关于试卷
        {
            path: 'member',
            component: () => import("@/views/crowd/member/vip/member"),
            name:"crowd/member",
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        {
            path: 'member-info',
            component: () => import("@/views/crowd/member/general/member-info"),
            name:"crowd/member-info",
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },

        // 个人试卷库
        {
            path: 'homework',
            component: () => import("@/views/crowd/homework/homework"),
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 个人试卷库
        {
            path: 'information',
            component: () => import("@/views/crowd/information"),
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
        // 个人试卷库
        {
            path: 'signIn',
            component: () => import("@/views/crowd/sign-in"),
            meta: {
                requireAuth: true,
                title: '个人试卷库',
                icon: 'lock',
                roles: ['admin', 'teacher', "user"] // you can set roles in root nav
            },
        },
    ]
}
